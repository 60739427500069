export const environment = {
  production: true,
  // win-server-test

  // reportingUrl: 'https://catalyzr.io/catalyzr-reporting',
  // reportingTool: 'https://catalyzr.io/queryresultsdemo?clientId=',
  // baseSignalRUrl: 'https://catalyzr.io/message',
  // baseAuthSignalRUrl: 'https://catalyzr.io/catalyzr-auth/message',
  // baseReportingSignalRUrl: 'https://catalyzr.io/catalyzr-reporting/message',
  // baseDownloadUrl: 'https://catalyzr.io/api/Files/Download?fileName=',
  // baseAuthUrl: 'https://catalyzr.io/catalyzr-auth/api/',
  // baseAppImageUrl: 'https://catalyzr.io/',
  // baseS3ImageUrl: 'https://catalyzr.io/api/s3/getImage?fileName=',
  // baseAuthApiURL: 'https://catalyzr.io/catalyzr-auth/api/auth/',
  // baseApiUrl: 'https://catalyzr.io/api/',
  // baseAuthServerUrl: 'https://catalyzr.io/catalyzr-auth/',
  // deliveryApi: 'https://catalyzr.io/api/delivery',
  // launchUriApi: 'https://catalyzr.io/api/delivery/',
  // feedbackUrl: 'https://catalyzr.io/catalyzrapp/employee/feedback',
  // employeeUrl: 'https://catalyzr.io/api/Employees',
  // baseUrl: 'https://catalyzr.io/',
  // taoQuestion: 'https://assessment.catalyzr.io/tao/Main/index?structure=items&ext=taoItems&section=manage_items&uri=https%3A%2F%2Fcatalyzr.io%2Ftao%2Ftaocat.rdf%23i157489052149506',
  // taoAssessments: 'https://assessment.catalyzr.io/tao/Main/index?structure=tests&ext=taoTests&section=manage_tests&uri=https%3A%2F%2Fcatalyzr.io%2Ftao%2Ftaocat.rdf%23i15789847434899258',
  // taoDeliveries: 'https://assessment.catalyzr.io/tao/Main/index?structure=delivery&ext=taoDeliveryRdf&section=manage_delivery_assembly&uri=https%3A%2F%2Fwww.tao.lu%2FOntologies%2FTAODelivery.rdf%23AssembledDelivery',


  reportingTool: 'https://api.catalyzr.info/queryresultsdemo?clientId=',
  baseSignalRUrl: 'https://api.catalyzr.info/message',
  baseDownloadUrl: 'https://api.catalyzr.info/api/Files/Download?fileName=',
  baseAppImageUrl: 'https://api.catalyzr.info/',
  baseS3ImageUrl: 'https://api.catalyzr.info/api/s3/getImage?fileName=',
  baseApiUrl: 'https://api.catalyzr.info/api/',
  deliveryApi: 'https://api.catalyzr.info/api/delivery',
  launchUriApi: 'https://api.catalyzr.info/api/delivery/',
  employeeUrl: 'https://api.catalyzr.info/api/Employees',
  baseUrl: 'https://api.catalyzr.info/',

  baseAuthSignalRUrl: 'https://auth.catalyzr.info/message',
  baseAuthUrl: 'https://auth.catalyzr.info/api/',
  baseAuthApiURL: 'https://auth.catalyzr.info/api/auth/',
  baseAuthServerUrl: 'https://auth.catalyzr.info/',

  feedbackUrl: 'https://catalyzr.info/employee/feedback',

  reportingUrl: 'https://reporting.catalyzr.info',
  baseReportingSignalRUrl: 'https://reporting.catalyzr.info/message',

  taoQuestion: 'https://assessment.catalyzr.info/tao/Main/index?structure=items&ext=taoItems&section=manage_items&uri=https%3A%2F%2Fcatalyzr.io%2Ftao%2Ftaocat.rdf%23i157489052149506',
  taoAssessments: 'https://assessment.catalyzr.info/tao/Main/index?structure=tests&ext=taoTests&section=manage_tests&uri=https%3A%2F%2Fcatalyzr.io%2Ftao%2Ftaocat.rdf%23i15789847434899258',
  taoDeliveries: 'https://assessment.catalyzr.info/tao/Main/index?structure=delivery&ext=taoDeliveryRdf&section=manage_delivery_assembly&uri=https%3A%2F%2Fwww.tao.lu%2FOntologies%2FTAODelivery.rdf%23AssembledDelivery',


  taoLoginUserName: 'admin',
  taoLoginPassword: 'admin',
  // baseDomainUrl: 'https://catalyzr.io/catalyzrapp/',
  baseDomainUrl: 'https://catalyzr.info/',
  contractUsToEmailAddress: 'Admin@Catalyzr.com',


  addGroup: true,
  groupList: false
};
