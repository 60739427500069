import { EmployeeProfileComponent } from './../employee-profile/employee-profile.component';
import { Component, OnInit, DoCheck, HostListener, ViewChild } from '@angular/core';
import { User } from 'src/app/_models/user';
import { Client } from 'src/app/_models/client';
import { UserRoles } from 'src/app/_models/userRoles';
import { FormControl } from '@angular/forms';
import { ClientJob } from 'src/app/_models/OLF/clientJob';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppAuthService } from 'src/app/pages/Shared/_services/auth.service';
import { Router } from '@angular/router';
import { AlertifyService } from 'src/app/pages/Shared/_services/alertify.service';
import { UiSyncService } from 'src/app/pages/Shared/_services/ui-sync.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ClientService } from 'src/app/pages/Shared/_services/client.service';
import { EmployeeUploadService } from 'src/app/pages/Shared/_services/employeeUpload.service';
import { RoleEnum } from 'src/app/_enums/RoleEnum';
import { SwitcherComponent } from '../switcher/switcher.component';
import { ProcessManagementService } from 'src/app/pages/Shared/_services/process-management.service';
import { SignalRService } from 'src/app/pages/Shared/_services/signalR.service';
import { HubResponseDto } from 'src/app/_models/Hubs/hub-response-dto';
import { Module } from 'src/app/_models/user-permission/module';
import { ModuleEnum } from 'src/app/_enums/module-enum';
import { TabEnum } from 'src/app/_enums/tab-enum';


@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit, DoCheck {
  @ViewChild(EmployeeProfileComponent, { static: false }) employeeProfileComponent: EmployeeProfileComponent;
  loading = true;

  baseUrl = environment.baseS3ImageUrl;
  reportingToolUrl = environment.baseDomainUrl + 'aqb-reporting/';
  catalyzrAdmin = null;
  catalyzrEmployee = null;
  isSelectAboutUs = false;
  isSelectLogin = false;
  isSelectSignUp = false;
  loginUser: User;
  userRoles = new Array();
  currentId: any;
  currentUser: any;

  isFromUrlRefresh = false;
  className = ' '
  isVisbleProcessIcon = false;
  isShowProcessIcon = false;

  signalRConnectionId: string;

  userId: number;
  dialogOpen = false;
  switcherOpen = false;
  clients: Client[] = [];
  allUserRoles: UserRoles[] = [];
  allUserModules: Module[] = [];
  selectedRoleId = 0;
  selectedRoleName = '';
  selectedClientId = 0;
  isDisableClienDropdown = false;
  isVisibleSwitcher = null;

  selectedModuleId = 0;

  control = new FormControl();
  clientJob: ClientJob[];
  jobLists: string[];
  filteredjobLists: Observable<string[]>;
  isShowSearch = false;
  isShowDropdown: boolean = true;
  searchKey: string;
  searchValue: string;

  key: any;

  employeeImageUrl: string;
  baseAuthUrl = environment.baseAppImageUrl;
  baseS3ImageUrl = environment.baseS3ImageUrl;
  messageUrl = environment.baseSignalRUrl;

  isCollapsed = false;
  isShowEmployeeProfile = null;
  isApplicableClientSwitcher = null;
  isApplicableReportingIcon = null;
  isApplicableReportingBackIcon = false;
  isApplicableProcessIcon = null;

  isProcessLoading = '';
  ProcessType: number;
  TotalRowCount: number;
  RowProcessCount: number;
  private subscriptions = new Subscription();
  constructor(
    public authService: AppAuthService,
    private router: Router,
    private alertify: AlertifyService,
    private uiSyncService: UiSyncService,
    public dialog: MatDialog,
    private clientService: ClientService,
    private processManagementService: ProcessManagementService,
    private employeeService: EmployeeUploadService,
    private signalRService: SignalRService
  ) {

    this.isFromUrlRefresh = true;
    this.subscriptions.add(this.authService.onLoggedIn.subscribe(user => {
      if (user) {
        this.userId = user.id;

      }
    }));

    this.subscriptions.add(this.uiSyncService.onDisplayAQBReporting.subscribe(res => {
      if (res) {
        this.isApplicableReportingBackIcon = true;
      } else {
        this.isApplicableReportingBackIcon = false;
      }
    }))
    // for role change
    // this.uiSyncService.onSelectedRoleId.subscribe(res => {
    //   if (res) {
    //     this.searchValue = '';
    //     this.selectedRoleId = res.roleId;
    //     this.checkAvailabilityForClientSwitcher();
    //     this.checkAvailabilityForReportingIcon();
    //     this.checkAvailabilityForProcessIcon();
    //     this.getSelectedRoleName();
    //     this.onRoleChange({ selectedRoleId: this.selectedRoleId, isUrl: res.isUrl });

    //     this.jobLists = [];
    //     // get the search bar job hints for employee
    //     if (this.authService.selectedRoleId === RoleEnum.Employee) {
    //       this.getClientJob();
    //     }
    //   } else {
    //     this.selectedRoleId = 0;
    //     this.selectedRoleName = '';
    //   }
    // });

    this.subscriptions.add(this.uiSyncService.onSelectedModule$.subscribe(res => {
      if (res) {
        this.searchValue = '';
        this.selectedModuleId = res;
        this.checkAvailabilityForClientSwitcher();
        this.checkAvailabilityForReportingIcon();
        this.checkAvailabilityForProcessIcon();
        // this.getSelectedRoleName();
        // this.onRoleChange({ selectedRoleId: this.selectedRoleId, isUrl: true });

        this.jobLists = [];
        // get the search bar job hints for employee
        if (this.selectedModuleId === ModuleEnum.Employee) {
          this.getClientJob();
        }
        this.setDefaultClientAndClientSelection();
      } else {
        this.selectedModuleId = 0;
        this.selectedRoleName = '';
      }
    }));

    this.subscriptions.add(this.uiSyncService.employeeImageUrl.subscribe(imageUrl => {
      if (imageUrl !== null && imageUrl !== undefined && imageUrl !== '') {
        this.employeeImageUrl = this.createS3ImgPath(imageUrl);
      } else {
        this.employeeImageUrl = '';
      }
    }));
    this.subscriptions.add(this.uiSyncService.onToggleEmployeeProfile.subscribe(isOpen => {
      if (isOpen === true) {
        this.openEmployeeHeader();
      }
    }));
    // update user roles
    this.subscriptions.add(this.uiSyncService.userAllRoles.subscribe(userRoles => {
      if (this.authService.currentUser && this.authService.currentUser.userRoles && this.authService.currentUser.userRoles.length > 0)
        this.allUserRoles = this.authService.currentUser.userRoles;
      else
        this.allUserRoles = [];
    }));

    // process icon loading enable/disable
    this.subscriptions.add(this.uiSyncService.isLoadingProcess.subscribe((res: boolean) => {
      // if (res === true) {
      //   this.isProcessLoading = 'loader';
      // } else {
      //   this.isProcessLoading = '';
      // }
      this.checkIsProcessRunning();
    }))

    this.subscriptions.add(this.uiSyncService.isLoadingProcessForClient.subscribe((res: boolean) => {
      this.checkIsProcessRunningForClient();
    }))

  }
  ngOnDestroy() {
    //Called once, before the instance is destroyed.
    this.subscriptions.unsubscribe();

  }
  ngDoCheck(): void {

    if (this.uiSyncService.selectedModuleId === ModuleEnum.Employee
      || this.uiSyncService.selectedModuleId === ModuleEnum.HrAdmin
      || this.uiSyncService.selectedModuleId === ModuleEnum.Applicant
      || this.uiSyncService.selectedModuleId === ModuleEnum.CampusRecruiter
      || this.uiSyncService.selectedModuleId === ModuleEnum.UniversityAdmin
      || this.uiSyncService.selectedModuleId === ModuleEnum.Student) {
      this.isShowSearch = true;
    } else {
      this.isShowSearch = false;
    }

    this.isVisibleSwitcherOnNavbar();
    this.subscriptions.add(this.uiSyncService.onClientSelected.subscribe(selectedClientId => {
      if (selectedClientId > 0) {
        this.selectedClientId = selectedClientId;
      } else {
        this.selectedClientId = 0;
      }
    }));

    this.loginUser = this.authService.currentUser;
    if (this.loginUser !== null && this.loginUser !== undefined && this.loginUser) {
      this.userId = this.authService.currentUser.id;
      // this.allUserRoles = this.loginUser.userRoles;
      this.allUserModules = this.loginUser.modules;
      if (this.allUserModules.length == 1) {
        this.isShowDropdown = false;
      } else {
        this.isShowDropdown = true;
      }
    }
  }

  async ngOnInit() {
    console.log('Navigation');

    this.subscriptions.add(this.uiSyncService.isVisbleProcessIcon.subscribe(value => {

      this.isVisbleProcessIcon = value;
    }, errer => console.log("Error from isVisbleProcessIcon.")));



    if (this.authService.onLoggedIn) {
      this.subscriptions.add(this.authService.onLoggedIn.subscribe(user => {
        this.loginUser = user;
        if (user) {
          this.userRoles = user.userRoles;
          this.allUserModules = user.modules;

          if (this.allUserModules.length == 1 && this.uiSyncService.selectedModuleId == ModuleEnum.Employee) {
            this.isShowDropdown = false;
          } else {
            this.isShowDropdown = true;
          }
          this.userId = user.id;
        }
        if (this.loginUser !== null && this.loginUser !== undefined) {
          const catalyzrAdmin = this.loginUser.modules.filter(c => c.id === ModuleEnum.Admin);
          if (catalyzrAdmin !== null && catalyzrAdmin !== undefined && catalyzrAdmin.length > 0) {
            this.catalyzrAdmin = true;
          } else {
            this.catalyzrEmployee = true;
          }
        }
        this.getUserById();
        this.getUserDetails();
      }, error => {
        console.log(error);
      }));
    }
    this.loginUser = this.authService.currentUser;
    if (this.loginUser !== null && this.loginUser !== undefined && this.loginUser) {
      this.userId = this.authService.currentUser.id;
      this.allUserRoles = this.loginUser.userRoles;
      if (this.allUserRoles.length == 1 && this.authService.selectedRoleId == RoleEnum.Employee) {
        this.isShowDropdown = false;
      } else {
        this.isShowDropdown = true;
      }
      this.authService.userRouteRedirection(true);
      //this.selectedRoleId = this.authService.selectedRoleId;

      this.selectedModuleId = this.uiSyncService.selectedModuleId;

      this.checkAvailabilityForClientSwitcher();
      this.checkAvailabilityForReportingIcon();
      this.checkAvailabilityForProcessIcon();
      this.getSelectedRoleName();
      // this.onRoleChange({ selectedRoleId: this.authService.selectedRoleId, isUrl: true });
      await this.getEmployeeImageUrl();
      await this.getClientLogoUrl();

      this.setDefaultClientAndClientSelection();
      this.isFromUrlRefresh = false;
    }
    this.getUserById();
    this.getUserDetails();
    // this.uiSyncService.onSignUpSelect.subscribe(response => {
    //   this.isSelectSignUp = response;
    // });
    // this.uiSyncService.onLoginSelect.subscribe(response => {
    //   this.isSelectLogin = response;
    // });
    // this.uiSyncService.onAboutUsSelect.subscribe(response => {
    //   this.isSelectAboutUs = response;
    // });
    // selected roleid and clientid getting from localstorage





    // this.uiSyncService.onSelectedRoleId.next(this.selectedRoleId);
    this.subscriptions.add(this.uiSyncService.isDisableClientDropdown.subscribe(isDisable => {
      this.isDisableClienDropdown = isDisable;
    }));
    this.isVisibleSwitcherOnNavbar();
    if (this.uiSyncService.selectedModuleId === ModuleEnum.Employee || this.uiSyncService.selectedModuleId === ModuleEnum.HrAdmin) {
      if (this.uiSyncService.selectedModuleId === ModuleEnum.Employee) {
        this.getClientJob();
      }
      this.isShowSearch = true;
    } else {
      this.isShowSearch = false;
    }



    //define signalR
    if (this.authService.loggedIn()) {
      if (this.signalRService.isConnected() && this.signalRService.currentSignalRPath === this.messageUrl) {
        this.signalRService.listenPorcessNotificationEvent();
        this.defineSignaling();
      } else {
        this.signalRService.connect(this.messageUrl, true).then(() => {
          this.signalRService.listenPorcessNotificationEvent();
          this.defineSignaling();
        }).catch(error => console.error('SignalR initial connection error:', error));
      }
      this.signalRService.onReconnected(() => {
        this.signalRService.listenPorcessNotificationEvent();
        this.defineSignaling();
      });
    }
  }

  //set default client
  setDefaultClientAndClientSelection() {

    let selectedClientId = localStorage.getItem('selectedClientId');
    let selectedClientName = localStorage.getItem('selectedClientName');
    this.selectedClientId = +selectedClientId;


    if (this.selectedClientId <= 0
    ) {
      this.selectedClientId = this.authService.currentUser.clientId;
    }

    if (this.selectedClientId > 0) {

      if (selectedClientName == null || selectedClientName == "" || selectedClientName == "null") {
        this.clientService.getClientById(this.selectedClientId).subscribe(client => {
          if (client !== undefined && client !== null) {
            selectedClientName = client.clientLegalName.toString();
            localStorage.setItem('selectedClientId', this.selectedClientId.toString());
            localStorage.setItem('selectedClientName', selectedClientName);
            this.uiSyncService.selectedClientName = selectedClientName;
          }
        })
      }



      if (this.authService.currentUser
        && !this.authService.currentUser.userRoles.some(c => c.roleId == RoleEnum.Admin)) {
        this.isDisableClienDropdown = true;
      }

    }

    this.uiSyncService.selectedClientId = this.selectedClientId;
    this.uiSyncService.selectedRoleId = this.selectedRoleId;
    this.uiSyncService.selectedClientName = selectedClientName;
    this.uiSyncService.onClientSelected.next(this.selectedClientId);
  }

  // check availability for client switcher
  checkAvailabilityForClientSwitcher() {
    if (this.selectedModuleId === ModuleEnum.Admin) {
      this.isApplicableClientSwitcher = true;
    } else {
      this.isApplicableClientSwitcher = false;
    }
  }
  // check the availability for reporting icon
  checkAvailabilityForReportingIcon() {
    if (this.selectedModuleId === ModuleEnum.Admin || this.selectedModuleId === ModuleEnum.HrAdmin) {
      this.isApplicableReportingIcon = true;
    } else {
      this.isApplicableReportingIcon = false;
    }
  }

  // check the availability for process icon
  checkAvailabilityForProcessIcon() {
    if (this.selectedModuleId === ModuleEnum.Admin) {
      // this.isApplicableProcessIcon = true;
      this.checkIsProcessRunning();
    } else {
      this.isApplicableProcessIcon = false;
    }
  }
  // check if any process is running for the user
  checkIsProcessRunning() {
    if (this.selectedClientId > 0) {
      this.processManagementService.isProcessRunningForClientOrUser(this.selectedClientId, this.userId).subscribe(res => {
        if (res === true) {
          this.isApplicableProcessIcon = true;
          this.isProcessLoading = 'loader';
        } else {
          this.isApplicableProcessIcon = false;
          this.isProcessLoading = '';
        }
      }, error => {


        this.isApplicableProcessIcon = false;
        this.isProcessLoading = '';
        this.alertify.error(error);
      });
    }
    const selectedClientId = localStorage.getItem('selectedClientId');

    if (selectedClientId == undefined || selectedClientId == null) {
      this.isApplicableProcessIcon = false;
      this.isProcessLoading = '';
    }
  }
  // check if any process is running for the client
  checkIsProcessRunningForClient() {
    if (this.selectedClientId > 0) {
      this.processManagementService.isProcessRunningForClient(this.selectedClientId).subscribe(res => {
        if (res === true) {
          this.isApplicableProcessIcon = true;
          this.isProcessLoading = 'loader';
        } else {
          this.isApplicableProcessIcon = false;
          this.isProcessLoading = '';
        }
      }, error => {
        this.isApplicableProcessIcon = false;
        this.isProcessLoading = '';
        this.alertify.error(error);
      });
    }
  }


  onClickLogin() {
    this.router.navigate(['/auth/login']);
  }
  onClickAboutUs() {
    this.router.navigate(['/landing-page']);
  }
  public createImgPath = (serverPath: string) => {
    return this.baseAuthUrl + `${serverPath}`;
  }
  public createS3ImgPath = (serverPath: string) => {
    var data = encodeURIComponent(serverPath);
    return this.baseUrl + `${data}`;
  }
  async getEmployeeImageUrl() {
    if (!this.loginUser.catalyzrPersonId) {
      return;
    }
    await this.employeeService.getEmployeeImageUrl(this.loginUser.catalyzrPersonId).then(imageUrl => {
      if (imageUrl !== null && imageUrl !== undefined && imageUrl !== '') {
        // this.employeeImageUrl = this.createImgPath(imageUrl);
        this.employeeImageUrl = this.createS3ImgPath(imageUrl);
      }
    }, error => {
      console.log(error);
    });
  }
  selectAboutUs() {
    this.isSelectAboutUs = !this.isSelectAboutUs;
    this.isSelectLogin = false;
    // if (this.isSelectLogin === true) {
    //   this.isSelectLogin = !this.isSelectLogin;
    // }
    // if (this.isSelectSignUp === true) {
    //   this.isSelectSignUp = !this.isSelectSignUp;
    // }

  }
  selectLogin() {
    this.isSelectAboutUs = false;
    this.isSelectLogin = !this.isSelectLogin;
    // if (this.isSelectAboutUs === true) {
    //   this.isSelectAboutUs = !this.isSelectAboutUs;
    // }
    // if (this.isSelectSignUp === true) {
    //   this.isSelectSignUp = !this.isSelectSignUp;
    // }
  }
  selectSignUp() {
    this.isSelectSignUp = !this.isSelectSignUp;
    this.isSelectAboutUs = true;
    // if (this.isSelectAboutUs === true) {
    //   this.isSelectAboutUs = !this.isSelectAboutUs;
    // }
    if (this.isSelectLogin === true) {
      this.isSelectLogin = !this.isSelectLogin;
    }
  }

  // get selected role name
  getSelectedRoleName() {
    if (this.loginUser !== null && this.loginUser !== undefined && this.loginUser.userRoles.length > 0 && this.selectedRoleId > 0) {
      if (this.selectedRoleId === RoleEnum.HR_Admin) {
        const hrRole = this.loginUser.userRoles.find(c => c.roleId === this.selectedRoleId);
        if (hrRole === null || hrRole === undefined) {
          this.selectedRoleName = '	HR Admin';
        } else {
          this.loginUser.userRoles.forEach(roles => {
            if (roles.role.id === this.selectedRoleId) {
              this.selectedRoleName = roles.role.roleName;
            }
          });
        }
      } else {
        this.loginUser.userRoles.forEach(roles => {
          if (roles.role.id === this.selectedRoleId) {
            this.selectedRoleName = roles.role.roleName;
          }
        });
      }
    }
  }

  getUserDetails() {

    const user = this.authService.currentUser;
    if (user != null) {
      // if(this.authService.selectedRoleId === RoleEnum.Employee){
      //   this.getClientJob();
      // }
      this.currentUser =
        user.firstName + ' ' + (user.middleName || '') + ' ' + user.lastName;
    }
  }
  getUserById() {
    if (this.loginUser) {
      if (this.authService.decodedToken) {
        this.currentId = this.authService.decodedToken.nameid;
      }
    }
  }
  logout() {
    this.isSelectAboutUs = false;
    this.isSelectLogin = false;
    // this.isSelectSignUp = false;
    this.authService.logout();
    this.authService.currentUser = null;
    this.loginUser = null;
    localStorage.removeItem('selectedRoleId');
    localStorage.removeItem('selectedClientId');
    // this.router.navigate(['/login']);
    this.router.navigate(['/landing-page']);
    console.log('Logged Out!');
    this.alertify.message('Logout Successfully!');
  }
  loggedId() {
    if (this.authService.loggedIn()) {
      this.getUserById();
      return true;
    } else {
      return false;
    }
  }

  onHomeClick() {
    this.router.navigate(['/landing-page']);
  }

  onRoleChange(roleSelection: { selectedRoleId: number, isUrl: boolean }) {
    this.authService.refreshCurrentUserModules(this.userId, 1, roleSelection.isUrl);
  }

  openEmployeeHeader(): void {
    this.isShowEmployeeProfile = !this.isShowEmployeeProfile;
    if (!this.isShowEmployeeProfile) {
      // this.employeeProfileComponent.closeDialog();
    }
  }

  // on click switcher
  async onClickSwitcher() {
    this.isCollapsed = !this.isCollapsed;
    await this.getClients();
    const dialogConfig = new MatDialogConfig();

    dialogConfig.minHeight = '50%';
    dialogConfig.maxWidth = '100%';
    dialogConfig.minWidth = '40%';
    dialogConfig.maxHeight = '55%';
    // dialogConfig.disableClose = true;
    dialogConfig.scrollStrategy;
    dialogConfig.position = {
      top: '50px',
      right: '30px'
    };

    dialogConfig.data = { clients: this.clients, selectedClientId: this.selectedClientId, selectedRoleId: this.selectedRoleId, isDisableClientDropdown: this.isDisableClienDropdown };
    if (this.switcherOpen === false) {
      var dialogRef = this.dialog.open(SwitcherComponent, dialogConfig);
      this.switcherOpen = !this.switcherOpen;
    } else {
      this.dialog.closeAll();
      this.switcherOpen = !this.switcherOpen;
    }
    dialogRef.afterClosed().subscribe(() => {
      this.switcherOpen = false;
    });
  }

  // get all clients
  async getClients() {
    await this.clientService.getAllClientsAsync().then((data: Client[]) => {

      this.clients = data;
    }, error => {
      console.log(error);
    });
  }
  // need update
  // is visible switcher on nav bar
  isVisibleSwitcherOnNavbar() {

    if (this.loginUser !== null && this.loginUser !== undefined) {
      if ((this.loginUser.userRoles !== null && this.loginUser.userRoles !== undefined && this.loginUser.userRoles.length > 1) || (this.selectedRoleId === RoleEnum.Admin)) {
        this.isVisibleSwitcher = true;
      } else {
        this.isVisibleSwitcher = false;
      }
    }
  }
  // client job search start
  onSearchClick(searchValue: any) {
    this.searchValue = this.searchValue.trim();
    // for employee
    if (this.uiSyncService.selectedModuleId === ModuleEnum.Employee) {
      this.jobLists = [];
      localStorage.setItem('searchKey', this.searchValue);
      localStorage.removeItem('loadMoreCount');
      // set the hr navigate route
      this.authService.setEmployeeNavigateRouteUrl('/employee/employee-landing-page', true);

      this.router.navigate(['/employee/client-job-search/', this.searchValue]);
    }
    // for employee
    if (this.uiSyncService.selectedModuleId === ModuleEnum.Applicant) {
      this.jobLists = [];
      localStorage.setItem('searchKey', this.searchValue);
      localStorage.removeItem('loadMoreCount');
      // set the hr navigate route
      this.authService.setEmployeeNavigateRouteUrl('/applicant/applicant-landing-page', true);

      this.router.navigate(['/applicant/client-job-search/', this.searchValue]);
    }

    // for hr
    if (this.uiSyncService.selectedModuleId === ModuleEnum.HrAdmin) {
      this.jobLists = [];
      localStorage.setItem('searchKey', this.searchValue);
      localStorage.removeItem('loadMoreCount');
      // set the hr navigate route url
      this.authService.setHrNavigateRouteUrl('/hr/hr-landing-page', true);

      if (this.authService.isSelectPeopleTab === true) {
        this.router.navigate(['/hr/hr-people-search', this.searchValue]);
      } else {
        this.router.navigate(['/hr/hr-job-search/', this.searchValue]);
      }

    }

    if (this.uiSyncService.selectedModuleId === ModuleEnum.CampusRecruiter) {

      console.log("Cr selected tab -- ", this.uiSyncService.selectedCrTab);

      if (this.uiSyncService.selectedCrTab == TabEnum.CareerTab) {
        this.router.navigate(['/cr/cr-job-search/', this.searchValue]);
      }
      else {
        this.router.navigate(['/cr/cr-people-search', this.searchValue]);
      }

    }

    // for hr
    if (this.uiSyncService.selectedModuleId === ModuleEnum.UniversityAdmin) {
      this.jobLists = [];
      localStorage.setItem('searchKey', this.searchValue);
      localStorage.removeItem('loadMoreCount');
      // set the hr navigate route url
      this.authService.setHrNavigateRouteUrl('/university/university-landing-page', true);
      this.router.navigate(['/university/university-people-search', this.searchValue]);

    }

    // for Student
    if (this.uiSyncService.selectedModuleId === ModuleEnum.Student) {
      this.jobLists = [];
      localStorage.setItem('searchKey', this.searchValue);
      localStorage.removeItem('loadMoreCount');
      // set the Student navigate route
      this.authService.setEmployeeNavigateRouteUrl('/student/student-landing-page', true);
      this.router.navigate(['/student/client-job-search/', this.searchValue]);
    }

  }
  // when logged out
  onLoggedOut(event: any) {
    this.searchValue = '';
    this.isProcessLoading = '';
  }
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.key = event.key;
  }

  searchKeyChange(value) {
    this.searchValue = value.target.value;
    const searchKey = value.target.value;
    // for employee
    if (this.uiSyncService.selectedModuleId === ModuleEnum.Employee) {
      if (searchKey !== null && searchKey !== undefined) {
        this.employeeService.getAllJobName(searchKey, this.authService.currentUser.clientId).subscribe((data: string[]) => {
          this.jobLists = data;
        }, error => {
          console.log(error);
        });
      }
    }
    // for applicant
    if (this.uiSyncService.selectedModuleId === ModuleEnum.Applicant) {
      if (searchKey !== null && searchKey !== undefined) {
        this.employeeService.getRequisitionName(searchKey, this.authService.currentUser.clientId).subscribe((data: string[]) => {
          this.jobLists = data;
        }, error => {
          console.log(error);
        });
      }
    }


    // for hr
    if (this.uiSyncService.selectedModuleId === ModuleEnum.HrAdmin) {
      console.log('Press: ' + searchKey);
    }
  }

  getClientJob() {

    this.clientJob = null;
    this.jobLists = [];
    // this.control.reset();

    let clientId = 0;
    if (this.authService.currentUser.clientId) {

      clientId = this.authService.currentUser.clientId;

      if (clientId <= 0) {
        if (localStorage.getItem('user')) {
          const user = JSON.parse(localStorage.getItem('user'));
          clientId = user.clientId;
        }
      }
    }

    if (clientId > 0) {
      this.employeeService.getClientJob(clientId).subscribe((data: ClientJob[]) => {
        this.clientJob = data;
        this.jobLists = this.clientJob.map((item) => {
          return item.jobName;
        });
      }, error => {
        console.log(error);
        this.alertify.error(error);
      });
    }
  }

  async getClientLogoUrl() {
    await this.clientService.getClientLogoUrl(this.loginUser.clientId).then(logoUrl => {
      if (logoUrl !== null && logoUrl !== undefined && logoUrl !== '') {
        const logoPath = this.createLogo(logoUrl);
        this.uiSyncService.clientLogoUrl.next(logoPath);
      }
    }, error => {
      console.log(error);
    });
  }
  public createLogo = (serverPath: string) => {
    var data = encodeURIComponent(serverPath);
    return this.baseS3ImageUrl + `${data}`;
  }

  onLoad() {
    this.loading = false;
  }

  // toggle navbar
  toggleNavBar() {
    this.isCollapsed = !this.isCollapsed;
  }
  onCloseEmployeeProfile(event: any) {
    this.isShowEmployeeProfile = false;

    // this.employeeProfileComponent.closeDialog();
  }
  // when click on reporting tool
  onClickReportingTool() {
    if (this.uiSyncService.selectedModuleId === RoleEnum.Admin) {
      if (this.selectedClientId > 0) {
        // window.location.href = this.reportingToolUrl + this.selectedClientId;
        // window.location.href = 'http://localhost:4200/aqb-reporting/' + this.selectedClientId;
        this.router.navigate(['/aqb-reporting/', this.selectedClientId]);
      } else {
        this.alertify.warning('Please select client.');
      }
    } else {
      // window.location.href = this.reportingToolUrl + this.loginUser.clientId;
      // window.location.href = 'http://localhost:4200/aqb-reporting/' + this.loginUser.clientId;
      this.router.navigate(['/aqb-reporting/', this.loginUser.clientId]);
    }

    // localStorage.setItem('isVisibleReportingBackIcon', JSON.stringify(true));
  }
  onClickReportingBackButton() {
    this.authService.onLogoClick();
  }
  toggleProcessModal() {
    this.isVisbleProcessIcon = !this.isVisbleProcessIcon;
  }
  processIconIsLoading(event: boolean) {
    if (event === true) {
      this.isProcessLoading = 'loader';
    } else {
      this.isProcessLoading = '';
    }
  }

  // define signalR events
  async defineSignaling() {
    // re-connected signalR
    this.signalRConnectionId = await this.signalRService.getConnectionId();


    // signalR events
    this.signalRService.define("EmployeeProcessSpinnerEvents", (data: HubResponseDto) => {
      if (data && data.ClientId == this.selectedClientId) {

        this.checkIsProcessRunningForClient();
      }
    });


    // signalR events
    this.signalRService.define("ResultCalculation_MessageAndStatusEvents", (data: HubResponseDto) => {

      if (data && data.ClientId == this.selectedClientId) {
        //get signalR response event from server
        if (data.OnSuccess || data.OnFailed) {
          this.checkIsProcessRunningForClient();
        }
      }
    });

    // signalR events
    this.signalRService.define("Nav_StatusProgressEvents", (data: HubResponseDto) => {

      if (data && data.ClientId == this.selectedClientId) {
        //get signalR response event from server
        this.ProcessType = data.FileUploadProcessType;
        this.TotalRowCount = data.TotalRowCount;
        this.RowProcessCount = data.RowProcessCount;
      }
    });
  }
}
