import { ClientPerformance } from './client-performance';
import { ClientModule } from './ClientModule';
export class Client {
  public id: number;
  public clientId: string;
  public corporateCode: string;
  public clientTypeId: number;
  public clientIndustryId: number;
  public clientLink: string;
  public ownerFirstName: string;
  public ownerLastName: string;
  public clientLegalName: string;
  public clientShortName: string;
  public firstAddress: string;
  public secondAddress: string;
  public countryId: number;
  public stateId: number;
  public city: string;
  public postCode: string;
  public championTitleId: number;
  public championFirstName: string;
  public championLastName: string;
  public championPhoneNoCodeId: number;
  public championEmail: string;
  public championPhoneNo: string;
  public vendorTitleId: number;
  public vendorFirstName: string;
  public vendorLastName: string;
  public vendorPhoneNoCodeId: number;
  public vendorEmail: string;
  public vendorPhoneNo: string;
  public logoUrl: string;
  public employeeBannerUrl: string;
  public hrBannerUrl: string;
  public startDate: Date;
  public endDate: Date;
  public isActive: boolean;
  public createdBy: number;
  public clientSecret: number;
  public createdDate: Date;
  public file?: File;
  public employeeBannerFile?: File;
  public hrBannerFile?: File;
  public clientPerformances: ClientPerformance[] = new Array();
  public isEncrypted: boolean;
  public clientModules: ClientModule[] = new Array();
  public validDomainOne?: string;
  public validDomainTwo?: string;
  public schoolName?: string;
  public applicationUrl?: string;
  public subDomain?: string;
}
