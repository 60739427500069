export class Role {
  id: number;
  roleName: string;
  createdById: number;
  createdDate: Date;
  isSelected: boolean;
  priority: number;
  isSystemReserved?: boolean;
  clientId?: number;
  client?: any;
}