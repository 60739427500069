import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SubdomainService {
  getSubdomain(): string {
    const host = window.location.hostname;
    const parts = host.split('.');

    if (parts.length > 2) {
      if (parts[0].toLowerCase() === 'www') {
        return parts[1];
      }
      return parts[0];
    }
    return '';
  }
}
