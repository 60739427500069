<!-- User details section end -->
<div id="employeeDetails" [ngClass]="
    isShowProfile === true
      ? 'employee_detsils_pop employee-active'
      : 'employee_detsils_pop'
  ">
  <div class="employee_detsils_border">
    <div class="closeIcon">
      <!-- <i (click)="closeEmployee()"  class="fa fa-times" aria-hidden="true"></i> -->
      <span (click)="closeEmployee()">&times;</span>
    </div>
    <div class="employee_detsils_left text-center">
      <h5 class="employee-name">
        {{ employeeInfo.firstName }} {{ employeeInfo.lastName }}
      </h5>
      <div class="employee_detsils_photo">
        <img *ngIf="loadingProfileIamge" class="card-img-top-user" src="assets/images/image-loading.gif"
          alt="loading" />
        <img [hidden]="loadingProfileIamge" class="img-fluid" (load)="onLoadProfileIamge()" [src]="imageUrl"
          alt="Employee Profile Image" />
      </div>
      <div class="employee_change_photo" *ngIf="!disableUploadProfile">
        <label title="Upload Photo">
          <a><img src="assets/employee/camera.png" alt="image" class="img-fluid" />
            Change Image</a>
          <input #myInput type="file" id="upload" accept="image/*" class="form-control"
            (change)="handleFileInput($event.target.files)" placeholder="Upload Photo" />
        </label>
      </div>
      <h5 class="employee-name-web">
        {{ employeeInfo.firstName }} {{ employeeInfo.lastName }}
      </h5>
      <small class="current-career" *ngIf="isEmployee" (click)="onClickCurrentCareer()">
        {{ employeeInfo.currentCareer }}</small>
      <p *ngIf="isEmployee" class="career-recommedation-cart color-two">
        CQ: {{ employeeInfo.cq }}
      </p>
    </div>
    <div class="employee_detsils_right">
      <div class="employee_right_top_info">
        <ul *ngIf="isEmployee">
          <li>Hire Date : {{ employeeInfo.hireDate | date }}</li>
          <li>Time in Role : {{ employeeInfo.timeInRole }}</li>
          <li class="managerName" *ngIf="
              hasManager === true &&
              employeeInfo.manager !== null &&
              employeeInfo.manager !== undefined &&
              employeeInfo.manager !== ''
            ">
            Manager : {{ employeeInfo.manager }}
          </li>
          <li class="managerName" *ngIf="
              hasManager === false &&
              (employeeInfo.manager === null ||
                employeeInfo.manager === undefined ||
                employeeInfo.manager === '')
            ">
            Manager :
            <span style="color: red; font-size: 15px; margin-top: 1px">
              &nbsp;No Employee Manager Found.</span>
          </li>
          <li>Performance : {{ employeeInfo.performance }}</li>
          <li>
            Willing to Relocate?
            <input type="checkbox" class="example-margin" color="primary" [checked]="isRelocateY"
              (change)="WillRelocate(true, $event.target.checked)" />Y
            <!-- [checked]="!employeeInfo.isRelocate" -->
            <input type="checkbox" class="example-margin" color="warn" [checked]="isRelocateN"
              (change)="WillRelocate(false, $event.target.checked)" />N
          </li>
          <li class="current-career">
            Current Career : {{ employeeInfo.currentCareer }}
          </li>
        </ul>
        <ul *ngIf="isApplicant">
          <li>Person Type : {{ employeeInfo.personType }}</li>
          <li>School : {{ employeeInfo.schoolName }}</li>
          <li>Major : {{ employeeInfo.major }}</li>
          <li>GPA : {{ employeeInfo.gpa }}</li>

          <li>Degree Type : {{ employeeInfo.degreeType }}</li>
          <li>
            Target Graduation Date : {{ employeeInfo.targetGraduationDate }}
          </li>
        </ul>
        <ul *ngIf="isStudent">
          <li>Person Type : {{ employeeInfo.personType }}</li>
          <form [formGroup]="employeeForm" (ngSubmit)="onSubmit()">
            <div>
              <label for="schoolName">School:</label>
              <input id="schoolName" formControlName="schoolName" />
            </div>

            <div>
              <label for="major">Major:</label>
              <input id="major" formControlName="major" />
            </div>

            <div class="select-dropdown">
              <label for="gpa">GPA:</label>
              <ng-select [items]="gpaValues" [bindLabel]="'value | number: \'1.1-1\''" bindValue="value"
                formControlName="gpa">
              </ng-select>
            </div>
            <div class="select-dropdown degreeType">
              <label for="degreeType">Degree Type:</label>
              <ng-select [items]="degreeTypes" bindLabel="label" bindValue="value" formControlName="degreeType">
              </ng-select>
            </div>
            <div class="select-dropdown targetGraduationDate">
              <label for="targetGraduationDate">Target Graduation Date:</label>
              <ng-select [items]="targetGraduationDates" bindLabel="label" bindValue="value"
                formControlName="targetGraduationDate">
              </ng-select>
            </div>

            <div class="update-button-container">
              <button type="submit" class="update-button" [ngClass]="enableUpdateButton?'activeButton':'inActiveButton'"
                [disabled]="!enableUpdateButton"><span *ngIf="isProcessing"
                  class="spinner-border spinner-border-sm mr-1 custom-spinner"
                  style="color: rgb(255, 255, 255);margin-left: 0px; margin-right: 5px; width: 14px; height: 14px;"></span>Update</button>
            </div>


          </form>
        </ul>
        <!-- default block -->
        <ul *ngIf="!isApplicant && !isEmployee && !isStudent">
          <li>Hire Date : {{ employeeInfo.hireDate | date }}</li>
          <li>Time in Role : {{ employeeInfo.timeInRole }}</li>
          <li *ngIf="
              hasManager === true &&
              employeeInfo.manager !== null &&
              employeeInfo.manager !== undefined &&
              employeeInfo.manager !== ''
            ">
            Manager : {{ employeeInfo.manager }}
          </li>
          <li *ngIf="
              hasManager === false &&
              (employeeInfo.manager === null ||
                employeeInfo.manager === undefined ||
                employeeInfo.manager === '')
            ">
            Manager :
            <span style="color: red; font-size: 15px; margin-top: 1px">
              &nbsp;No Employee Manager Found.</span>
          </li>
          <li>Performance : {{ employeeInfo.performance }}</li>
          <li>
            Willing to Relocate?
            <input type="checkbox" class="example-margin" color="primary" [checked]="isRelocateY"
              (change)="WillRelocate(true, $event.target.checked)" />Y
            <!-- [checked]="!employeeInfo.isRelocate" -->
            <input type="checkbox" class="example-margin" color="warn" [checked]="isRelocateN"
              (change)="WillRelocate(false, $event.target.checked)" />N
          </li>
        </ul>
        <div class="employee_logout">
          <a (click)="logout()">logout</a>
        </div>
      </div>
      <div>
        <h6>OTP Enabled Status</h6>
        <mat-slide-toggle style="margin-right: 10px; z-index: 0; padding-bottom: 10px" color="primary"
          [checked]="otpLoginEnabled" (change)="onToggleChange($event)" [disabled]="disableToggole">
        </mat-slide-toggle>
        <mat-checkbox [(ngModel)]="smsOption" (change)="applyChanges($event)" [disabled]="disableSmsOption">
          SMS Option
          <span>
            <mat-icon (mouseover)="popupShow()" style="color: #6400ff; margin-top: -4px; cursor: pointer;">
              information
            </mat-icon>
          </span>
        </mat-checkbox>


      </div>
      <div class="employee_self_info">
        <h6>Self-identified Information:</h6>
        <p>
          While not mandatory, this data is useful in scoring assessments
          against statical norms, and thus providing more accurate career
          recommendations.
        </p>
      </div>
      <div class="employee_self_info_bottom">
        <div class="employee_self_inner_bottom">
          <form class="d-flex justify-content-between">
            <!-- <div class="employee_self_inner_form">
              <label>Age:</label>
              <div class="select">
                <select (change)="onAgeChange($event.target.value)" [(value)]="selectedAge" name="slct" id="slct"
                  class="employeeCategory">
                  <option selected disabled>Select Age</option>
                  <option [value]="age" *ngFor="let age of ages">{{age}}</option>
                </select>
              </div>
            </div> -->
            <!-- <div class="employee_self_inner_form">
              <label> Identified Gender:</label>
              <div class="select">
                <select [(ngModel)]="selectedGender" name="slct" id="slct" class="employeeCategory">
                  <option selected disabled>Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
            </div> -->
            <div class="employee_self_inner_form">
              <label class="race-etinicity">Race/Ethinicity:</label>
              <div class="select">
                <form #raceIds="ngForm">
                  <ng-multiselect-dropdown *ngIf="
                      ethnicities !== null &&
                      ethnicities !== undefined &&
                      ethnicities.length > 0
                    " [placeholder]="'Select Race'" [settings]="dropdownSettings" [data]="data"
                    [(ngModel)]="selectedRaces" name="selectedRace" #selectedRace="ngModel"
                    (onDeSelect)="onDeSelectItem($event)" (onSelect)="onItemSelect($event)">
                  </ng-multiselect-dropdown>
                </form>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="employee_logout-bottom">
        <a (click)="logout()">logout</a>
      </div>
    </div>
  </div>
</div>
<div id="myModal" class="modal" *ngIf="isShowPopup === true">
  <div class="modal-content-wrapper">
    <div class="modal-content">
      <p>By submitting this form and entering your phone number above, you agree to receive automated text
        messages from Catalyzr and agree to our
        <a href="https://www.catalyzr.com/terms-of-service" target="_blank" rel="noopener noreferrer">Terms
          of Service</a> and
        <a href="https://www.catalyzr.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy
          Policy</a>.
        Consent is not a condition of any purchase. Msg frequency varies. Msg and data rates may apply.
        Reply HELP for help and STOP to cancel.
      </p>
    </div>
    <div class="modal-close">
      <img class="closeBtn" (click)="onClosePopup()" src="assets/login/close.png" alt="">
    </div>
  </div>
</div>
<!-- User details section end -->

<app-image-framing-croping *ngIf="showPopup" (FileToUpload)="afterApplyCrop($event)"
  (closeShowPopup)="closeShowPopup($event)" [showPopup]="showPopup" [imageUrl]="imageUrl" [fileName]="fileName"
  [fileFormat]="fileFormat"></app-image-framing-croping>