

import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ClientType } from 'src/app/_models/clientType';
import { ClientIndustry } from 'src/app/_models/clientIndustry';
import { Country } from 'src/app/_models/country';
import { Client } from 'src/app/_models/client';
import { ClientDto } from 'src/app/_models/dto/clientDto';
import { State } from 'src/app/_models/state';
import { PhoneNoCode } from 'src/app/_models/phoneNoCode';
import { ClientPerformanceDto } from 'src/app/_models/dto/client-performancedto';
import { ClientModuledto } from 'src/app/_models/dto/ClientModuledto';
import { param } from 'jquery';
import { UpdateClient } from 'src/app/_models/updateClient';
import { UserRegistrationLogDTO } from 'src/app/_models/dto/userRegistrationLogDTO';

const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  )
};
@Injectable({
  providedIn: 'root'
})
export class ClientService {
  baseAPI = environment.baseApiUrl;
  baseAuthUrl = environment.baseAuthUrl;
  constructor(private http: HttpClient) { }
  getAllClientTypes(): Observable<ClientType[]> {
    return this.http.get<ClientType[]>(this.baseAPI + 'ClientTypes');
  }
  updateClientModules(inputrequest: ClientModuledto) {
    return this.http.put(this.baseAPI + 'clients/updateclientmodules/' + inputrequest.clientId, inputrequest, { headers: httpOptions.headers });
  }
  getClientsTotalLength() {
    return this.http.get(this.baseAPI + 'Clients/totallength', { headers: httpOptions.headers });
  }
  // client industry part
  getAllClientIndustries(): Observable<ClientIndustry[]> {
    return this.http.get<ClientIndustry[]>(this.baseAPI + 'ClientIndustries');
  }
  saveClientIndustry(clientIndustry: ClientIndustry) {
    return this.http.post(this.baseAPI + 'ClientIndustries', clientIndustry, { headers: httpOptions.headers });
  }
  getClientIndustryById(id: number) {
    return this.http.get(this.baseAPI + 'ClientIndustries/' + id, { headers: httpOptions.headers });
  }
  updateClientIndustry(clientIndustry: ClientIndustry) {
    return this.http.put(this.baseAPI + 'ClientIndustries/' + clientIndustry.clientIndustryId, clientIndustry, { headers: httpOptions.headers });
  }
  deleteClientIndustry(id: number) {
    return this.http.delete(this.baseAPI + 'ClientIndustries/' + id, { headers: httpOptions.headers });
  }
  getAllCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(this.baseAPI + 'countries');
  }
  getStatesByCountry(countryId: number) {
    return this.http.get(this.baseAPI + 'states/' + countryId);
  }
  getAllPersonTitle() {
    return this.http.get(this.baseAPI + 'PersonTitles');
  }
  getAllPhoneNoCode() {
    return this.http.get(this.baseAPI + 'PhoneCodes');
  }
  getClientId() {
    return this.http.get(this.baseAPI + 'clients/GetClientId', { headers: httpOptions.headers });
  }
  saveClient(client: UpdateClient) {
    return this.http.post(this.baseAPI + 'clients', client);
  }
  saveClientPerformances(inputRequest: ClientPerformanceDto) {
    return this.http.post(this.baseAPI + 'clients/saveclientperformances', inputRequest, { headers: httpOptions.headers });
  }
  async isExistClientLink(clientDto: ClientDto): Promise<boolean> {
    return await this.http.get<boolean>(this.baseAPI + 'clients/IsExistClientLink/', { params: { clientLink: clientDto.clientLink, operation: clientDto.operation, clientId: clientDto.clientId } }).toPromise();
  }
  getAllClients() {
    return this.http.get<Client[]>(this.baseAPI + 'clients');
  }
  async getAllClientsAsync(): Promise<Client[]> {
    return await this.http.get<Client[]>(this.baseAPI + 'clients').toPromise();
  }
  getClientById(id: number) {
    return this.http.get<any>(this.baseAPI + 'clients/' + id);
  }

  encryptClientData(clientId: number, userId: number, signalRConnectionId: string) {
    return this.http.get(this.baseAPI + 'clients/encryptClient/',
      {
        headers: httpOptions.headers,
        params: {
          ClientId: clientId.toString(),
          UserId: userId.toFixed(),
          ConnectionId: signalRConnectionId
        }
      });
  }

  async getStateByCountry(countryId: number): Promise<State[]> {
    return this.http.get<State[]>(this.baseAPI + 'states/' + countryId).toPromise();
  }
  async getStateByStateId(id: number): Promise<State> {
    return this.http.get<State>(this.baseAPI + 'states/GetState/' + id).toPromise();
  }
  getPhoneNoCode(id: number): Promise<PhoneNoCode> {
    return this.http.get<PhoneNoCode>(this.baseAPI + 'PhoneCodes/' + id).toPromise();
  }
  updateClient(id: number, client: UpdateClient) {
    return this.http.put(this.baseAPI + 'clients/' + id, client);
  }
  updateClientPerformances(inputrequest: ClientPerformanceDto) {
    return this.http.put(this.baseAPI + 'clients/updateclientperformances/' + inputrequest.clientId, inputrequest, { headers: httpOptions.headers });
  }
  getClientDeliveryLink(corporateCode: string) {
    return this.http.get(this.baseAPI + 'Clients/GetClientDeliveryLink/' + corporateCode, { headers: httpOptions.headers });
  }
  getClientDeliveryUrl(clientId: number) {
    return this.http.get(this.baseAPI + 'Clients/GetClienDeliverytUrl/' + clientId, { headers: httpOptions.headers });
  }
  async getClientByHasCorporateCode(hashCorporateCode: string): Promise<Client> {
    return await this.http.get<Client>(this.baseAPI + 'clients/GetClientByHashCorporateCode/', { params: { HashCorporateCode: hashCorporateCode }, headers: httpOptions.headers }).toPromise();
  }
  async getClientBySubDomain(subdomain: string): Promise<Client> {
    return await this.http.get<Client>(this.baseAPI + 'clients/GetClientBySubDomain/', { params: { SubDomain: subdomain }, headers: httpOptions.headers }).toPromise();
  }
  IsExistSubDomain(subdomain: string, clientId: number): Observable<any> {
    return this.http.get<any>(this.baseAPI + 'clients/IsExistSubDomain/', {
      params: { subDomain: subdomain, clientId: clientId },
      headers: httpOptions.headers
    });
  }



  getAllActiveAndInActiveClients() {
    return this.http.get(this.baseAPI + 'Clients/GetAllActiveAndInActiveClients');
  }
  getClientsData(pageNo: number) {
    return this.http.get(this.baseAPI + 'Clients/GetPaginatedClients/' + pageNo + '/page');
  }
  async search(searchValue: string, pageNo: number): Promise<Client[]> {
    return await this.http.get<Client[]>(this.baseAPI + 'Clients/search/' + searchValue + '/pageno/' + pageNo, { headers: httpOptions.headers }).toPromise();
  }
  async getClientLogoUrl(clientId: number): Promise<string> {
    return await this.http.get<string>(this.baseAPI + 'clients/' + clientId + '/logo', { headers: httpOptions.headers }).toPromise();
  }
  removeClientLogo(clientId: number) {
    return this.http.delete(this.baseAPI + 'clients/' + clientId + '/removeClientLogo', { headers: httpOptions.headers });
  }
  removeEmployeeBannerImage(clientId: number) {
    return this.http.delete(this.baseAPI + 'clients/' + clientId + '/removeEmployeeBanner', { headers: httpOptions.headers });
  }
  removeHrBannerImage(clientId: number) {
    return this.http.delete(this.baseAPI + 'clients/' + clientId + '/removeHrBanner', { headers: httpOptions.headers });
  }
  deleteClient(clientId: number) {
    return this.http.delete(this.baseAPI + 'clients/' + clientId, { headers: httpOptions.headers });
  }

  deleteClientbyParams(clientId: number, param: any) {


    return this.http.delete(this.baseAPI + 'clients/' + clientId, { headers: httpOptions.headers, observe: 'body', params: param })
    //return this.http.delete<any>(this.baseAPI + 'clients/' + clientId, { body: params,headers: httpOptions.headers });


  }




}
