import { SubdomainService } from 'src/app/_utility/subdomain.service';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SubdomainCookieService {
  expiryDays = 30;
  expiryDate = new Date();
  baseAppUrl = environment.baseDomainUrl;
  constructor(private cookieService: CookieService, private subdomainService: SubdomainService) { }

  // Set the subdomain cookie after a successful login
  setSubdomainCookie(subdomain: string): void {
    this.expiryDate.setDate(this.expiryDate.getDate() + this.expiryDays);

    // Extract the top-level domain (TLD) from the current hostname
    const domainParts = window.location.hostname.split('.');
    const domain = domainParts.slice(-2).join('.');

    console.log('Original extracted domain:', domain);

    // Ensure the domain is set without a leading dot
    const finalDomain = domain.startsWith('.') ? domain.slice(1) : domain;

    console.log('Final domain used for cookie:', finalDomain);

    // Set the cookie for the extracted domain without the leading dot
    this.cookieService.set(
      'userSubdomain',
      subdomain,
      this.expiryDate,
      '/',
      finalDomain, // Use the domain without a leading dot
      true,         // Secure flag
      'Lax'         // SameSite policy
    );

    console.log('Cookie set for subdomain:', subdomain);
  }




  // Update the subdomain cookie if the user logs in via a different subdomain
  updateSubdomainCookie(newSubdomain: string): void {
    this.expiryDate.setDate(this.expiryDate.getDate() + this.expiryDays);

    // Update the cookie with the new subdomain
    this.cookieService.set('userSubdomain', newSubdomain, this.expiryDate, '/', '', true, 'Lax');
    console.log('Cookie updated for subdomain:', newSubdomain);
  }

  // Get the subdomain from the cookie
  getSubdomainFromCookie(): string | null {
    return this.cookieService.get('userSubdomain') || null;
  }

  // Check if the subdomain cookie exists and redirect the user
  redirectToSubdomainIfNeeded(): void {
    const currentSubdomain = this.subdomainService.getSubdomain();
    console.log("Current subdomain from service:", currentSubdomain);


    // Remove 'https://' or 'http://' and any trailing slashes
    this.baseAppUrl = this.baseAppUrl.replace(/^https?:\/\//, '').replace(/\/$/, '');

    console.log("Base application URL after processing:", this.baseAppUrl);

    if (!currentSubdomain) {
      const storedSubdomain = this.getSubdomainFromCookie();

      if (storedSubdomain) {
        const currentUrl = window.location.hostname;
        console.log("Current URL (hostname):", currentUrl);

        // Extract the base domain from the current URL by removing the subdomain
        const domainParts = currentUrl.split('.');
        const baseDomain = domainParts.slice(-2).join('.');
        console.log("Extracted base domain from current URL:", baseDomain);

        console.log("Processed base application URL:", this.baseAppUrl);
        console.log("Stored subdomain from cookie:", storedSubdomain);

        // Check if the extracted base domain matches the baseAppUrl
        if (baseDomain === this.baseAppUrl) {
          // Redirect to the stored subdomain with the base URL
          const newUrl = `https://${storedSubdomain}.${this.baseAppUrl}`;
          console.log(`Redirecting to subdomain URL: ${newUrl}`);
          window.location.href = newUrl;
        } else {
          console.log("Base domain does not match the baseAppUrl. No redirect needed.");
        }
      } else {
        console.log('No subdomain found in cookies.');
      }
    } else {
      console.log("Subdomain is already present, no need for redirection.");
    }
  }



  deleteSubdomainCookie(): void {
    this.cookieService.delete('userSubdomain', '/');
    console.log('Cookie deleted for subdomain');
  }
}
