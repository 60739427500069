import { UiSyncService } from 'src/app/pages/Shared/_services/ui-sync.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppAuthService } from 'src/app/pages/Shared/_services/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent implements OnInit {

  @Input('color') color: string;
  @Input('isDialog') isDialog: boolean;
  @Input('logoUrl') logoUrl: string = 'assets/logo/logo.png';
  @Output('closeDialog') closeDialog: EventEmitter<boolean> = new EventEmitter();
  whiteImgSrc = 'assets/logo/logo.png';
  blackImgSrc = 'assets/images/catalyzr-logo-black.png';
  private subscriptions = new Subscription();
  loading = true;
  constructor(
    private uiSyncService: UiSyncService,
    private authService: AppAuthService,
    private route: Router,
  ) { }

  ngOnInit() {
    // if (this.color === 'white') {
    //   this.img = this.whiteImgSrc;
    // }
    // if (this.color === 'black') {
    //   this.img = this.blackImgSrc;
    // }
    // this.subscriptions.add(this.uiSyncService.clientLogoUrl.subscribe(logoUrl => {
    //   if (logoUrl !== null && logoUrl !== undefined && logoUrl !== '') {
    //     this.logoUrl = logoUrl;
    //   }
    // }));
  }
  ngOnDestroy() {
    //Called once, before the instance is destroyed.
    this.subscriptions.unsubscribe();

  }
  loggedId() {
    if (this.authService.loggedIn()) {
      return true;
    } else {
      return false;
    }
  }
  clickInLogo() {
    this.uiSyncService.onAboutUsSelect.next(false);
    this.uiSyncService.onLoginSelect.next(false);
    if (this.isDialog !== true) {
      this.uiSyncService.onClientRemoveFromClientDashboard.next(true);
    }

    if (this.isDialog === true) {
      this.closeDialog.emit(true);
    }
    if (this.loggedId()) {
      // this.authService.onClickLandingPageChange();
      this.authService.onLogoClick();
    } else {
      this.route.navigate(['/auth/login']);
    }
  }
  onLoad() {
    this.loading = false;
  }
}
