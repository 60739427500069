import { UserRoles } from './userRoles';
import { Module } from './user-permission/module';
export class User {
  public id: number;
  public resultId: number;
  public userRoleId: number;
  public duration: string;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public userName: string;
  public password: string;
  public email: string;
  public authUserId: number;
  public authUser: User;
  public isChecked: boolean;
  public isActive: boolean;
  public companyCode: string;
  public status: boolean;
  public imageUrl: string;
  public verificationCode: string;
  public fullName: string;
  public accessToken: string;
  public hashCorporateCode: string;
  public hashUserName: string;
  public clientId: number;
  public catalyzrPersonId: string;
  public selectedRolesId: number[] = new Array();
  public userRoles: UserRoles[] = new Array();
  public modules: Module[] = new Array();
  public personType: string;
  public schoolName: string;
  public degreeType: string;
  public gPA4PointScale: number;
  public gPA5PointScale: number;
  public major: string;
  public targetGraduationDate: number;
  public isOTPEnabled: boolean;
  public isSMSEnabled?: boolean;
  public mobilePhoneNumberPersonal: string;

  public GetFullName() {
    if (this.middleName === null) {
      this.fullName = this.firstName + ' ' + this.lastName;
    } else {
      this.fullName = this.firstName + ' ' + this.middleName + ' ' + this.lastName;
    }
  }

}
